<template>
  <div>
    <vs-radio
      v-model="row.singleSelection"
      :vs-value="true"
      class="pointer"
      color="#0B2772"
      @change.stop="onClick"
    >
      {{ text }}
    </vs-radio>
  </div>
</template>
<script>
export default {
  data: () => ({
    radio: 'light',
  }),
  props: {
    text: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: this,
    }
  },
  methods: {
    onClick() {
      this.$emit('on:radio-button-check', this.row);
    }
  },
}
</script>
